import AxiosRepository from 'http/axios/axios.repository';

const RESOURCE = 'besoul';

export class BeSoulService extends AxiosRepository {
    async getEdenBadges(edenId) {
        return super.getByIdWithClient(edenId, `${RESOURCE}/badges`);
    }

    async getPendingReports(skip, limit) {
        return super.getWithClient(`${RESOURCE}/reports/pending?skip=${skip}&limit=${limit}`);
    }

    async getHistoryReports() {
        return super.getWithClient(`${RESOURCE}/reports/history`);
    }

    async processReport(payload) {
        return super.postWithClient(payload, `${RESOURCE}/reports/process`);
    }

    async getSubscription() {
        return super.getWithClient(`${RESOURCE}/subscriptions`);
    }

    async getSubscriptionByRetailer() {
        return super.getWithClient(`${RESOURCE}/subscriptions/retailer`);
    }

    async getPendingRequest(page, limit) {
        return super.getWithClient(`${RESOURCE}/validations/pending?page=${page}&limit=${limit}`);
    }

    async getValidationsFile(id, path) {
        return super.getWithClient(`${RESOURCE}/validations/get-file/${id}?path=${path}`, true);
    }

    async getRequestHistory() {
        return super.getWithClient(`${RESOURCE}/validations/history`);
    }

    async processRequest(payload) {
        return super.postWithClient(payload, `${RESOURCE}/validations/process`);
    }

    async getUsersOfToday() {
        return super.getWithClient(`${RESOURCE}/users/today?limit=50`);
    }

    async getAllUsers(skip, limit) {
        return super.getWithClient(`${RESOURCE}/users/all?skip=${skip}&limit=${limit}`);
    }

    async getPendingEdens() {
        return super.getWithClient(`${RESOURCE}/edens-web/pending`);
    }

    async getEdensHistory() {
        return super.getWithClient(`${RESOURCE}/edens-web/history`);
    }

    async processReviewEdenWeb(payload) {
        return super.postWithClient(payload, `${RESOURCE}/edens-web/process`);
    }

    async getStats() {
        return super.getWithClient(`${RESOURCE}/subscriptions/stats/retailer`);
    }

    async getReferralsParams() {
        return super.getWithClient(`${RESOURCE}/refferal-program`);
    }

    async updateReferralsParams(payload) {
        return super.putWithClient(payload, `${RESOURCE}/refferal-program`);
    }

    async getEventsByUser(email) {
        return super.getWithClient(`analytics/eventsByUser/${email}`);
    }

    async getAllEvents(payload) {
        return super.postWithClient(payload, `/analytics/allEvents`);
    }

    async getAvgBeSoulProcess(params) {
        return super.getWithClient(`analytics/avg-be-soul-process?initDate=${params.initDate}&finalDate=${params.finalDate}`);
    }
}

export const beSoulHttpService = new BeSoulService();
